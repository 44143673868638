<template>
  <div class="main">
    <div class="goodsdetail">
      <div class="left">
        <div class="item">
          <div class="name">商品名称: </div>
          <div class="text">{{ detail.name }}</div>
        </div>
        <div class="item">
          <div class="name">副标题: </div>
          <div class="text">{{ detail.title }}</div>
        </div>

          <div class="itembox">
            <div class="name">商品主图: </div>
            <div class="mainImageUrl">
              <img :src="detail.mainImageUrl"
                   alt=""
                   @click.self="showBigImage($event)">
            </div>
          </div>
          <div class="itembox">
            <div class="name">轮播图: </div>
            <div class="photoList">
              <img v-for=" (item,index) in detail.photoList"
                   :key="index"
                   :src="item.imageUrl"
                   alt=""
                   @click.self="showBigImage($event)">
            </div>
          </div>

        <div class="itembox">
            <div class="name">商品详情图: </div>
            <div class="detailphoto">
              <img v-for=" (item,index) in detail.goodsDetail"
                   :key="index"
                   :src="item.imageUrl"
                   alt=""
                   @click.self="showBigImage($event)">
            </div>
          </div>

      </div>
      <div class="center">
        <div>
          <div class="item">
          <div class="name">供货商名称: </div>
          <div class="text">{{ detail.storeName }}</div>
        </div>
          <div class="item">
          <div class="name">成本价: </div>
          <div class="text">{{ detail.costPriceStr }}</div>
        </div>
        <div class="item">
          <div class="name">商品重量: </div>
          <div class="text">{{ detail.quality + detail.basicUnit + detail.meterUnit }}</div>
        </div>
        <div class="item">
          <div class="name">商品库存: </div>
          <div class="text">{{ detail.stock }}</div>
        </div>
        <div class="item">
          <div class="name">商品状态: </div>
          <div class="text">{{ detail.enabled ? '上架' :'下架' }}</div>
        </div>
      </div>
       <div>

          <div class="item">
          <div class="name">团长姓名: </div>
          <div class="text" v-if="detail.brokerData">{{ detail.brokerData.realName }}</div>
          </div>
          <div class="item">
          <div class="name">团购价: </div>
          <div class="text">{{ detail.priceStr }}</div>
        </div>

        <div class="item">
          <div class="name">附加单位: </div>
          <div class="text">{{ detail.extraUnit }}</div>
        </div>
        <div class="item">
          <div class="name">已售件数: </div>
          <div class="text">{{ detail.saleFakeCount }}</div>
        </div>
        <div class="item">
          <div class="name">截止时间: </div>
          <div class="text">{{detail.expirationTime}}</div>
        </div>
       </div>
        <div>
          <div class="item">
          <div class="name">团长手机号: </div>
          <div class="text" v-if="detail.brokerData">{{ detail.brokerData.mobile }}</div>
          </div>
          <div class="item">
          <div class="name">划线价: </div>
          <div class="text">{{ detail.listPriceStr }}</div>
        </div>
        <div class="item">
          <div class="name">商品单位: </div>
          <div class="text">{{ detail.bulkUnit }}</div>
        </div>
        </div>
       <div>
       </div>
      </div>
      <div class="goback" @click="$router.push('/group/goods/list')">返回</div>
    </div>

    <!-- //显示放大图片的组件 -->
    <BigImg :visible="photoVisible"
            :url="bigImgUrl"
            @closeClick="()=>{photoVisible=false}"></BigImg>
  </div>

</template>
<script>
import BigImg from "@/components/BigImg.vue";
import { goodsIdWord } from '@/api/group'
export default {
  data () {
    return {
      detail: {},
      photoVisible: false,
      bigImgUrl: "",
    }
  },
  created () {
    this.getGoodsIdWord()
  },
  components: {
    BigImg
  },
  methods: {
    showBigImage (e) {//点击图片函数，点击后，把photoVisible设置成true
      if (e != "") {
        this.photoVisible = true;
        this.bigImgUrl = e.currentTarget.src;
      }
    },
    getGoodsIdWord () {
      goodsIdWord({ id: this.$route.query.id }).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
.goback{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  line-height: 40px;
  width: 140px;
  height: 40px;
  // background: #279dfb;
  border-radius: 4px;
  color: #333;
  border: #ccc 1px solid;
}
.el-main {
  background: #fff !important;
}
.main {
  display: flex;
  background: #fff !important;
}
.itembox {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .name {
    width: 100px;
    font-size: 16px;
    color: #333;
    font-family: PingFang SC;
    font-weight: 500;
    text-align: right;
  }
}
.goodsdetail {
  height: 100%;
  padding: 50px;
  width: 100%;
  .left {
    margin-right: 80px;
  }
}
.item {
  width: 265px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    width: 100px;
    font-size: 16px;
    color: #333;
    font-family: PingFang SC;
    font-weight: 500;
    text-align: right;
  }
  .text {
    text-align: left;
    width: 150px;
    font-size: 14px;
    font-family: PingFang SC;
    color: #606266;
  }
}
.center{
  display: flex;
  .item{
    width: 265px;
  }
}
.mainImageUrl {
  margin-left: 15px;
  width: 120px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
  }
}
.photoList {
  margin-top: 20px;
  margin-left: 15px;
  display: flex;
  img {
    width: 120px;
    height: 120px;
    margin-right: 10px;
  }
}
.detailphoto {
  margin-top: 20px;
  margin-left: 15px;
  display: flex;
  img {
    width: 120px;
    height: 120px;
    margin-right: 10px;
  }
}
</style>
